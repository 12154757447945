<template>
  <div>

    <a-modal v-model="visible" destroyOnClose :confirmLoading="loading" width="1000px" :footer="null" :maskClosable="false" :cancel="onClose">
      <div slot="title">库存批次出入库记录</div>

      <a-tabs default-active-key="1" @change="onChange">
      <a-tab-pane key="1" tab="出库">
        <a-row style="margin-top: 12px;">
        <a-table
          size="small"
          :columns="columnsout"
          :dataSource="batchOutLists"
          rowKey="id"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >

        </a-table>
      </a-row>
      </a-tab-pane>
      <a-tab-pane key="2" tab="入库" force-render>
        <a-row style="margin-top: 12px;">
        <a-table
          size="small"
          :columns="columnsin"
          :dataSource="batchInLists"
          rowKey="id"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >

        </a-table>
      </a-row>
      </a-tab-pane>

    </a-tabs>



    </a-modal>
  </div>
</template>

<script>
import { batchOutFlowsList, batchInFlowsList } from '@/api/warehouse'

  export default {
    name: 'FormModal',
    props: ['visible', 'form'],
    model: { prop: 'visible', event: 'cancel' },
    data() {
      return {
        columnsout: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 60,
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
         {
          title: "产品名称",
          dataIndex: "goods_name",
          sorter: true,
        },
        {
          title: "批次号",
          dataIndex: "batch_number",
        },
        {
          title: "出库数量",
          dataIndex: "stock_out_quantity",
        },
        {
          title: "出库类型",
          dataIndex: "out_type",
        },
        {
          title: "出库时间",
          dataIndex: "create_time",
        },
      ],
      columnsin: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 60,
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
         {
          title: "产品名称",
          dataIndex: "goods_name",
          sorter: true,
        },
        {
          title: "批次号",
          dataIndex: "batch_number",
        },
        {
          title: "入库数量",
          dataIndex: "stock_in_quantity",
        },
        {
          title: "入库类型",
          dataIndex: "in_type",
        },
        {
          title: "出库时间",
          dataIndex: "create_time",
        },
      ],
        loading: false,
        pagination: { current: 1, total: 0, pageSize: 20 },
        batchOutLists: [],
        batchInLists: [],
        tk: 1,
      };
    },
    mounted() {
    },
    methods: {
      onClose() {
        this.$emit('cancel');
      },
      onChange(k) {
        this.pagination.current = 1;
        if (k == 1) {
            this.tk = 1;
            this.listout(this.form)
        } else if (k == 2) {
            this.tk = 2;
            this.listin(this.form)
        }
      },
      tableChange(pagination, filters, sorter) {
          this.pagination.current = pagination.current;
          if (this.tk == 1) {
            this.listout(this.form);
          } else if (this.tk == 2) {
             this.listin(this.form);
          }

      },
      listout(batchid) {
      this.batchOutLists = [];
      this.loading = true;
      batchOutFlowsList({"batch": batchid, page:this.pagination.current, size: 20})
        .then((data) => {
          this.pagination.total = data.count;
          this.batchOutLists = data.results;
        })
        .finally(() => {
         this.loading = false;
        });
      },

      listin(batchid) {
      this.batchOutLists = [];
      this.loading = true;
      batchInFlowsList({"batch": batchid, page:this.pagination.current, size: 20})
        .then((data) => {
          this.pagination.total = data.count;
          this.batchInLists = data.results;
        })
        .finally(() => {
         this.loading = false;
        });
      },

    },
  }
</script>

<style scoped>
</style>